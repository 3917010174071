<template>
  <div style="overflow: auto !important; min-height: 100vh">
    <b-button-toolbar class="text-white p-2">
      <b-button @click="$emit('return')" class="w-100" variant="primary">
        <b-icon icon="chevron-left" />
        Return to Your Library
      </b-button>
    </b-button-toolbar>
    <b-button
      v-for="(item, i) in videos"
      :key="i"
      :disabled="item.type == 'expandableChapterHeader'"
      class="p-3 w-100"
      style="background-color: transparent; border: none"
      :style="{
        backgroundColor: item === currentChapter ? '' : 'transparent',
      }"
      @click="$emit('chapterSelected', item)"
    >
      {{ item.displayName }}
    </b-button>
  </div>
</template>
<script>
export default {
  props: ["videos", "currentChapter"],
}
</script>